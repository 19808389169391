<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <Home msg="Welcome to Your Vue.js App" /> -->
    <router-view />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" id="footer">
          <p id="contact">
            <span class="upper"> Telephone </span>
            <a href="tel:+41445526590">+41 44 552 65 90</a>
            <br />
            <span class="upper">Email </span>
            <a href="mailto:info@bureauluethi.com">info@bureauluethi.com</a>
            <br />
          </p>
          <p id="adress">
            Bureau Lüthi AG <br />St. Jakobstrasse 31 <br />8004 Zürich<br />Switzerland
          </p>
          <div class="icons d-lg-none">
            <a
              href="https://instagram.com/bureauluethi?igshid=1ccsdbdsgejdt"
              target="_blank"
            >
              <img src="@/assets/icons/instagram.svg" alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/bureau-lüthi-architecture-zürich/"
              target="_blank"
            >
              <img src="@/assets/icons/linkedin-2.svg" alt="" />
            </a>
          </div>
          <p id="social">
            <span class="d-none d-lg-block"
              ><a
                href="https://instagram.com/bureauluethi?igshid=1ccsdbdsgejdt"
                target="_blank"
                >Instagram</a
              ><a
                href="https://www.linkedin.com/company/bureau-lüthi-architecture-zürich/"
                target="_blank"
                >LinkedIn</a
              ></span
            >© {{ year }} Bureau Lüthi AG <br /><router-link to="/impressum"
              >Impressum</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap-scss/bootstrap-grid.scss";
*,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 8px;
}

.upper {
  text-transform: uppercase;
}

#app {
  font-family: atten-new, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

h2 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 4rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

p {
  font-size: 3rem;
  line-height: 150%;
  margin-bottom: 2rem;

  &.center {
    text-align: center;
  }
}

a {
  text-decoration: none;
  color: black;
}

#footer {
  width: 100%;
  // padding: 0 8rem;
  padding: 0 5%;
  margin-top: 20rem;

  display: flex;

  .icons {
    display: flex;
    justify-content: center;

    img {
      width: 40px;
      margin: 0 16px 40px 16px;
    }
  }

  p {
    font-size: 2.5rem;
    line-height: 140%;

    margin-bottom: 5rem;
    &#contact {
      @media (min-width: 992px) {
        margin-right: 5vw;
      }
    }

    &#social {
      margin-left: auto;
      text-align: right;

      a {
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    flex-direction: column;

    p {
      text-align: center;
      &#social {
        text-align: center;
        margin-left: 0px;
      }
    }
  }
}
</style>
