import Vue from "vue";
import App from "./App.vue";
import router from './router';

Vue.config.productionTip = false;

import LottieAnimation from 'lottie-web-vue'
Vue.use(LottieAnimation); // add lottie-animation to your global scope

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");