import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '*',
        redirect: '/'
    }, {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import('../views/Impressum.vue'),
        meta: {
            title: 'Impressum'
        }
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    }
})

// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     const currentUser = firebase.auth().currentUser

//     if (requiresAuth && !currentUser) {
//         next('/login')
//     } else if (!requiresAuth && currentUser) {
//         // next()
//         next('/')
//     } else {
//         next()
//     }
// });

export default router;